var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"my-3",attrs:{"justify":"center"}},_vm._l((_vm.admins),function(admin){return _c('v-btn',{key:admin,staticClass:"mx-1 mb-2 px-2",attrs:{"dense":"","x-small":"","disabled":_vm.search == admin},on:{"click":function($event){_vm.search = admin}}},[_vm._v(" "+_vm._s(admin)+" ")])}),1),_c('v-data-table',{staticClass:"elevation-1 tb px-2",attrs:{"headers":_vm.Headers,"items":_vm.trainees,"search":_vm.search,"custom-filter":_vm.filterApplicants,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('b',[_vm._v("Trainees Information ")])]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(value))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"icon":"","small":"","title":"Click to delete ","color":"error"},on:{"click":function($event){return _vm.del(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"my-6"},[_c('trainee-details',{attrs:{"item":item}}),_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((_vm.actions),function(action){return _c('v-col',{key:("action-" + action),staticClass:"my-0 py-0 mx-2",attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":action,"false-value":0,"true-value":1},on:{"change":function($event){return _vm.saveChanges(item, action)}},model:{value:(item[action]),callback:function ($$v) {_vm.$set(item, action, $$v)},expression:"item[action]"}})],1)}),_c('v-col',{staticClass:"my-0 py-0 mx-2",attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":"Fee"},on:{"change":function($event){return _vm.saveChanges(item, 'fee')}},model:{value:(item.fee),callback:function ($$v) {_vm.$set(item, "fee", $$v)},expression:"item.fee"}})],1),_c('v-col',{staticClass:"my-0 py-0 mx-2",attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":"Payment"},on:{"change":function($event){return _vm.saveChanges(item, 'payment')}},model:{value:(item.payment),callback:function ($$v) {_vm.$set(item, "payment", $$v)},expression:"item.payment"}})],1),_c('v-col',{staticClass:"my-0 py-0 mx-2",attrs:{"cols":"auto"}},[_c('v-textarea',{attrs:{"label":"Remark","dense":"","auto-grow":"","rows":"1"},on:{"change":function($event){return _vm.saveChanges(item, 'remark')}},model:{value:(item.remark),callback:function ($$v) {_vm.$set(item, "remark", $$v)},expression:"item.remark"}})],1)],2)],1)])]}}])}),_c('v-snackbar',{attrs:{"bottom":"","color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")]),_vm._v(" Close ")],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('p',[_vm._v(_vm._s(_vm.snackMsg))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }