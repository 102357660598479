<template>
  <div>
    <v-row justify="center" class="my-3">
      <v-btn
        dense
        x-small
        v-for="admin in admins"
        :key="admin"
        :disabled="search == admin"
        class="mx-1 mb-2 px-2"
        @click="search = admin"
      >
        {{ admin }}
      </v-btn>
    </v-row>
    <v-data-table
      :headers="Headers"
      :items="trainees"
      class="elevation-1 tb px-2"
      :search="search"
      :custom-filter="filterApplicants"
      show-expand
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>Trainees Information </b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.createdAt="{ value }">
        {{ value | formatDate }}
      </template>
      <template #item.actions="{ item }">
        <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="my-6">
            <trainee-details :item="item" />
            <v-row no-gutters>
              <v-col
                v-for="action in actions"
                :key="`action-${action}`"
                cols="auto"
                class="my-0 py-0 mx-2"
              >
                <v-switch
                  v-model="item[action]"
                  :label="action"
                  :false-value="0"
                  :true-value="1"
                  @change="saveChanges(item, action)"
                ></v-switch>
              </v-col>
              <v-col cols="auto" class="my-0 py-0 mx-2">
                <v-text-field
                  v-model="item.fee"
                  label="Fee"
                  @change="saveChanges(item, 'fee')"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="my-0 py-0 mx-2">
                <v-text-field
                  v-model="item.payment"
                  label="Payment"
                  @change="saveChanges(item, 'payment')"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="my-0 py-0 mx-2">
                <v-textarea
                  v-model="item.remark"
                  label="Remark"
                  dense
                  auto-grow
                  rows="1"
                  @change="saveChanges(item, 'remark')"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </div>
        </td>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
import TraineeDetails from "@/components/TraineeDetails.vue";
export default {
  name: "TrainingInfo",
  mixins: [snackMixin],
  components: {
    TraineeDetails,
  },
  data() {
    return {
      search: "",
      Headers: [
        { text: "Fullname", value: "fullname" },
        { text: "Phone", value: "phone" },
        { text: "Gender", value: "gender" },
        { text: "Address", value: "address" },
        { text: "Created Date", value: "createdAt" },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ],
      admins: [
        "shortlisted",
        "interviewed",
        "admitted",
        "resumed",
        "active",
        "payment",
      ],
      actions: ["shortlisted", "interviewed", "admitted", "resumed", "active"],
      trainees: [],
    };
  },

  created() {
    apiClient
      .get("/trainees?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.trainees = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    del(item) {
      const ok = confirm(
        `Are sure you want to delete ${item.fullname} records?`
      );
      if (ok) {
        this.displayMsg(`Deleting data, please wait...`);
        apiClient
          .delete(`/trainees?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(`Data not successfully deleted`, "error");
              return;
            } else {
              this.trainees = this.trainees.filter((pt) => pt.id !== item.id);
              this.displayMsg(`Data successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg(err.message, "error"));
      }
    },
    async saveChanges(item, type) {
      this.loading = true;
      item.type = type;
      try {
        const res = await apiClient.put("/trainees", item);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
        } else {
          this.displayMsg(res.data, "error");
        }
      } catch (err) {
        this.displayMsg(err.message, "error");
      }
    },

    filterApplicants(value, search, item) {
      switch (search) {
        case "shortlisted":
          return !!item.shortlisted;
        case "interviewed":
          return !!item.interviewed;
        case "admitted":
          return !!item.admitted;
        case "resumed":
          return !!item.resumed;
        case "active":
          return !!item.active;
        case "payment":
          return item.payment != 0.0;
        default:
          return (
            value != null &&
            search != null &&
            value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
